import AppErrorBoundary from "./AppErrorBoundary";
import { ENTRY, INCIDENT_REPORT, NETWORK } from "constants/routes";
import { createBrowserRouter, Navigate } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: ENTRY,
    lazy: () => import("./App"),
    ErrorBoundary: AppErrorBoundary,
    children: [
      {
        index: true,
        element: (
          <Navigate to={NETWORK.concat(window.location.search)} replace />
        ),
      },
      {
        path: NETWORK,
        lazy: () => import("modules/network/Network"),
      },
      {
        path: INCIDENT_REPORT,
        lazy: () => import("modules/incident-report/IncidentReport"),
      },
    ],
  },
]);

export default router;
