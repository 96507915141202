import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_BASE_URL + "/graphql-api/",
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const message =
    typeof graphQLErrors === "object" && graphQLErrors?.[0]?.message;

  console.log(graphQLErrors, networkError);

  if (
    message === "token is expired" ||
    message === "Unable to parse authentication" ||
    message === "Error decoding token headers." ||
    message === "Wrong token found, try again later."
  ) {
    window.localStorage.clear();
    window.location.href = "/login";
  }
});

const authLink = setContext((_, { headers }) => {
  const params = new URLSearchParams(window.location.search);
  const access_token = params.get("access_token");

  return {
    headers: {
      ...headers,
      authorization: access_token ? `JWT ${access_token}` : "",
    },
  };
});

const client = new ApolloClient({
  defaultOptions: { query: { notifyOnNetworkStatusChange: true } },
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

export default client;
