import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import ApolloProvider from "providers/ApolloProvider";
import MuiThemeProvider from "providers/MuiThemeProvider";
import { RouterProvider } from "react-router-dom";
import router from "router";
import MuiSnackbarProvider from "providers/MuiSnackbarProvider";
import MuiDatePickerProvider from "providers/MuiDatePickerProvider";

mapboxgl.accessToken =
  "pk.eyJ1Ijoiam9zZWRhY2hlIiwiYSI6ImNsbzh2M3g3djAwbG8ycXFuczRjdzBpdjYifQ.LT3Pluh2WKNbb22yMQHoQA";

// const params = new URLSearchParams(window.location.search);
// const access_token = params.get("access_token");

// if (access_token) {
//   localStorage.setItem("auth", JSON.stringify({ access_token }));
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider>
      <MuiThemeProvider>
        <MuiDatePickerProvider>
          <MuiSnackbarProvider>
            <RouterProvider router={router} />
          </MuiSnackbarProvider>
        </MuiDatePickerProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
