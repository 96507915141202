import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const commonPaletteOptions = {
  primary: {
    main: "#006600",
  },
  secondary: {
    main: "#006600",
  },
};

export const theme = responsiveFontSizes(
  createTheme({
    cssVariables: true,
    colorSchemes: {
      light: {
        palette: {
          ...commonPaletteOptions,
        },
      },
      dark: false,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        "2xl": 1536,
      },
    },
    typography: {
      fontFamily: ["'Montserrat'", "Helvetica", "Arial", "sans-serif"].join(),
      fontSize: 12,
      allVariants: {
        fontWeight: 500,
      },
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiIcon: {
        defaultProps: {
          baseClassName: "material-symbols-rounded",
        },
      },
      MuiLoadingButton: {
        defaultProps: {
          variant: "contained",
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
          disableElevation: true,
          disableFocusRipple: true,
        },
      },
    },
  })
);

export default theme;
